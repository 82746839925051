import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";

const LoadingSpinner = ({ message, marginTop = 3, fontColour }) => {
  return (
    <Row>
      <Col className="d-flex flex-column justify-content-center align-items-center">
        <Spinner />
        {message && message.length && (
          <p
            className={`mt-${marginTop && marginTop} mb-0 ${
              fontColour && fontColour
            }`}
          >
            {message}
          </p>
        )}
      </Col>
    </Row>
  );
};
export default LoadingSpinner;
