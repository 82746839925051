import {Row, Col} from "react-bootstrap";

const PrivacyPolicy = () => {
    return (<Row className="mt-5 mt-lg-10 mb-5 mb-lg-10 justify-content-center">
        <Col md={10}>
            <h1 className="mb-5 text-center">Privacy Policy</h1>
            <h1>Privacy Policy for Medi-Dashboard - Mobile Health App</h1>

            <p><em>Effective Date: 30th January 2024</em></p>

            <h2>1. Introduction</h2>

            <p>Welcome to Medi-Dashboard ("we," "us," or "our"). This Privacy Policy outlines how we collect, use,
                disclose, and protect your information when you use our mobile health app (the "App"). The App is
                designed for patients being treated at clinics in the USA, Canada, Norway, Sweden, and Finland.
                Access
                to the App is granted through the care provider's Electronic Health Record (EHR) system. By using
                the
                App, you agree to the terms of this Privacy Policy.</p>

            <h2>2. Information We Collect</h2>

            <p>
                <strong>a. Patient Information:</strong><br/>
                When your care provider uploads your information to the EHR system, we may collect personal
                information,
                such as your name, date of birth, and contact details.
            </p>

            <p>
                <strong>b. Health Information:</strong><br/>
                The App collects health-related information, including data requested by your care provider for
                treatment purposes. This information is made visible on the care provider's EHR software.
            </p>

            <p>
                <strong>c. Access Logs:</strong><br/>
                We collect logs of your interactions with the App when accessed through the EHR system, including
                login
                times and features used.
            </p>

            <h2>3. How We Use Your Information</h2>

            <p>
                <strong>a. Providing Services:</strong><br/>
                We use your information to provide you with access to the App and its features, facilitating
                communication between you and your care provider.
            </p>

            <p>
                <strong>b. Integration with EHR:</strong><br/>
                Your information is integrated into the App from your care provider's EHR system, ensuring accurate
                and
                up-to-date health records.
            </p>

            <p>
                <strong>c. Communication:</strong><br/>
                We may send emails relevant to your treatment or containing important information about the App.
                These
                communications are vital for your well-being and the proper use of the App.
            </p>

            <p>
                <strong>d. App Maintenance and Updates:</strong><br/>
                Periodically, we perform maintenance on the App and introduce new features to enhance your
                experience.
                We ensure these changes align with your care provider's treatment goals.
            </p>

            <h2>4. Sharing Your Information</h2>

            <p>
                <strong>a. Care Providers:</strong><br/>
                Your information is shared with your care provider, who uploads and manages your health data within
                the
                EHR system.
            </p>

            <p>
                <strong>b. Email Communication:</strong><br/>
                Relevant emails are sent to you based on your treatment and important App-related information. We do
                not
                share your email address with third parties.
            </p>

            <p>
                <strong>c. App Maintenance and Updates:</strong><br/>
                When conducting maintenance or introducing new features, only necessary personnel with strict
                confidentiality and security measures have access to the App's codebase.
            </p>

            <h2>5. Security</h2>

            <p>
                We implement stringent security measures to protect your information from unauthorized access,
                disclosure, alteration, and destruction. Your data is encrypted during transmission and at rest.
                Regular
                security audits and updates are conducted to ensure the highest level of protection.
            </p>

            <h2>6. Your Choices</h2>

            <p>
                As a patient accessing the App through your care provider's EHR system, your choices related to data
                access, correction, and deletion are managed through your care provider's policies and procedures.
            </p>

            <h2>7. Children's Privacy</h2>

            <p>
                The App is not intended for individuals under the age of 13. We do not knowingly collect personal
                information from children under 13 without parental consent.
            </p>

            <h2>8. Changes to This Privacy Policy</h2>

            <p>
                We may update this Privacy Policy from time to time. Any changes will be communicated through your
                care
                provider or by posting the new Privacy Policy on this page.
            </p>

            <h2>9. Contact Us</h2>

            <p>
                If you have any questions or concerns about this Privacy Policy, please contact your care provider
                or
                reach out to us through the contact information provided by your care provider.
            </p>

            <p><em>By using the App, you agree to the terms of this Privacy Policy.</em></p>

        </Col>
    </Row>);
}

export default PrivacyPolicy;
