import {Container, Row, Col} from "react-bootstrap";

const Footer = () => {
    return (<footer id="footer" className="mt-4 mt-lg-10">
        <Container>
            <Row className="text-center">
                <Col id="copyright" className="pt-4 pb-4">Copyright © 2023 Medi-Dashboard.</Col>
            </Row>
        </Container>
    </footer>)
}
export default Footer;
