import {useState} from "react";
import {Container} from "react-bootstrap";
import {BrowserRouter, Switch, Route} from "react-router-dom"
//
import MainHeader from "./MainHeader/MainHeader";
import Homepage from "./HomePage/Homepage";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";
import PrivacyPolicy from "./Privacy Policy/PrivacyPolicy";

const Routes = () => {
    return (<BrowserRouter>
        <Switch>
            <Route exact path="/">
                <Homepage/>
            </Route>
            <Route path="/product">
                <h1>Product</h1>
            </Route>
            <Route path="/contact">
                <Contact/>
            </Route>
            <Route path="/privacy-policy">
                <PrivacyPolicy/>
            </Route>
        </Switch>
    </BrowserRouter>);
}

const App = () => {
    const [mainHeaderOpen, setMainHeaderOpen] = useState(false);
    return (<>
        <MainHeader mainHeaderOpen={mainHeaderOpen} setMainHeaderOpen={setMainHeaderOpen}/>
        <Container id="mainContainer" className={mainHeaderOpen && "mainHeaderOpen"}>
            <Routes/>
        </Container>
        <Footer/>
    </>)
}

export default App;
