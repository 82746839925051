import {Navbar, Container, Nav} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars} from '@fortawesome/free-solid-svg-icons'
//
import logoBanner from "../Assets/MD_logo_big.svg"

const MainHeader = ({mainHeaderOpen, setMainHeaderOpen}) => {
    return (<Navbar id="mainHeader" expand="lg">
        <Container>
            <Navbar.Brand href="/" className="p-0"><img id="logoBanner" loading="eager" src={logoBanner}/></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"
                           onClick={() => setMainHeaderOpen(!mainHeaderOpen)}><FontAwesomeIcon
                icon={faBars}/></Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav" className="text-center">
                <Nav className="ms-auto">
                    <Nav.Link className="mt-4 mt-lg-0" href="/contact">CONTACT</Nav.Link>
                </Nav>
                <a href="https://calendly.com/medi-dashboard/30min" target="_blank" className="btn btn-primary mt-3 mt-lg-0 ms-lg-2"
                   role="button">BOOK A DEMO</a>
            </Navbar.Collapse>
        </Container>
    </Navbar>)
}

export default MainHeader;
