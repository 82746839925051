import {useState} from "react";
import {Row, Col, Form, Button, Alert} from "react-bootstrap";
import emailjs from '@emailjs/browser';
//
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const Contact = () => {
    const [loading, setLoading] = useState();
    const [success, setSuccess] = useState();
    const [error, setError] = useState();

    const sendEmail = (e) => {
        e.preventDefault();
        setError();
        setSuccess();

        setLoading("Sending message");

        const templateParams = {
            from_name: e.target[0].value,
            from_clinic_name: e.target[1].value,
            from_email: e.target[2].value,
            message: e.target[3].value,
        }

        emailjs.send('service_7wcl20h', 'template_1w9prei', templateParams, 'b2OWYOk2XEBZVC4Xb')
            .then((result) => {
                //todo: add timeout
                console.log(result.text);
                setSuccess("Message sent! We will get back to you ASAP.")
                setLoading();
            }, (error) => {
                console.log(error.text);
                //todo: "or contact us @Medi-Dashboard..."
                setError("Sorry, there was a problem sending your message. Please refresh the page and try again.")
                setLoading();
            });
    };

    return (<><Row className="mt-5 mt-lg-10 mb-5 mb-lg-10 justify-content-center">
        <Col sm={9} md={7} lg={6}>
            <h1 className="mb-5 text-center">Product related question or sales inquiry?</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && (<Alert variant="success">{success}</Alert>)}
            {loading ? <LoadingSpinner message={loading}/> : <Form onSubmit={sendEmail}>
                <Form.Group className="mb-3">
                    <Form.Label>Your name</Form.Label>
                    <Form.Control type="text" name="from_name" required/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Your company/clinic</Form.Label>
                    <Form.Control type="text" name="from_clinic_name" required/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" name="from_email" required/>
                    <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-5">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={4} type="text" name="message" required/>
                </Form.Group>
                <Row className="text-center">
                    <Col>
                        <Button type="submit" size="lg">Send</Button>
                    </Col>
                </Row>
            </Form>}
        </Col>
    </Row><Row><Col className="mb-4">
        <div id="specificQuestions" className="jumbo d-flex flex-column align-items-center">
            <h1 className="mb-5 text-center">Specific questions?</h1>
            <p className="text-center mb-5">We understand the requirements of clinicians and we are here to help. Fee
                free to book a demo and we'll answer any questions you might have.</p>
            <a href="https://calendly.com/medi-dashboard/30min?" target="_blank" className="btn btn-primary ms-lg-2"
               role="button">Book demo call</a>
        </div>
    </Col></Row>
    </>)
}

export default Contact;
