import {Card, Col, Row} from "react-bootstrap";
//
import homePageTopGraph from "../Assets/homepage_top_graph.webp"
import HomePageCardGraph1 from "../Assets/Collect_Image.webp"
import HomePageCardGraph2 from "../Assets/Visualise_Image.webp"
import HomePageCardGraph3 from "../Assets/report.webp"

const Homepage = () => {

    const cards = [{
        title: "Collect",
        img: HomePageCardGraph1,
        text: ["Collect patient data using our library of common psychiatric health questionnaires & daily scoring functionality.", "Have your patients interact with our best-in-class user interfaces; during a clinic visit, or via our accompanying mobile app.",]
    }, {
        title: "Visualise",
        img: HomePageCardGraph2,
        text: ["Data is dynamically output via interactive graphs tailored to common psychiatric questionnaires.", "Visuals are easy to read and share with your patients, reassuring them progress is being recorded.", "Having all data viewable in one place avoids reams of paper forms.", "Raw data is retained in PDF format, in a way that mimics traditional paper questionnaires.",]
    }, {
        title: "Report",
        img: HomePageCardGraph3,
        text: ["Easily generate custom reports in PDF format.", "Send to patient relevant individuals, via our in-app, HIPAA and GDPR compliant email service.",]
    }]

    return (<>
        <Row className="mt-4 mt-md-5 mt-lg-8 mt-xl-10">
            <Col className="text-center"><h1>Dashboards For Pioneering Clinicians</h1></Col>
        </Row>
        <Row className="mt-5 mt-lg-8">
            <Col className="text-center">
                <p className="mb-0">
                    Real-time patient data helps healthcare providers track progress during treatment, gain
                    valuable insights, and optimise patient care.
                </p>
            </Col>
        </Row>
        <Row className="mt-5 mt-lg-8"><Col className="d-flex justify-content-center">
            <img id="homePageTopGraph" loading="eager" src={homePageTopGraph} fetchPriority="high"></img>
        </Col></Row>
        <Row className="mt-5 mt-lg-8 mt-xl-10">
            {cards.map((card, i) => {
                const {title, img, text} = card;
                return (<Col xs={12} md={6} className="mb-4">
                    <Card className={`text-center cardHover ${i % 2 !== 0 ? "even" : ""}`}>
                        <Card.Img loading="lazy" variant="top" src={img}/>
                        <Card.Body>
                            <Card.Title className="text-center">{title}</Card.Title>
                            {text.map((paraGraph, index) => <Card.Text
                                className={index !== text.length - 1 ? "mb-4" : null}>
                                {paraGraph}
                            </Card.Text>)}
                        </Card.Body>
                    </Card>
                </Col>)
            })}
        </Row>
    </>)
}

export default Homepage;
